<template>
  <InteriorLayout type="list" :hasSearch="false">
    <div class="interior-office-wrapper mt-10">
      <div class="thumbnail-img-wrap">
        <div 
          class="thumbnail-img"
          :style="{
            backgroundImage: image ? `url(${image})` : '',
          }"
        >
        </div>
        <div v-if="isLoggedIn" @click.stop="onFavorite">
          <eva-icon
            class="absolute"
            :name="isFavorite ? 'star' : 'star-outline'"
            :width="40"
            :height="40"
            :fill="isFavorite ? '#283cf0' : '#fff'"
          />
        </div>
      </div>

      <div class="content">
        <div class="flex flex-row items-center justify-between">
          <p class="place">{{ address }}</p>
        </div>
        <h1>{{ data.title }}</h1>

        <p class="explain">
          {{ data.explanation }}
        </p>

        <p class="date">업체 등록일 {{ date }}</p>

        <p v-if="data.isConfirm" class="hint">
          ※ 비유에서 직접 확인하고 승인한 업체 입니다.
        </p>

        <div class="button-group flex flex-row items-center">
          <button
            @click="goToOffer"
            class="flex flex-row items-center justify-center"
          >
            <eva-icon
              name="clipboard-outline"
              :width="20"
              :height="20"
              :fill="'#fff'"
            />
            의뢰하기
          </button>
        </div>
      </div>
      <div v-if="portfolioImages.length > 0" class="portfolio">
        <h1>포트폴리오</h1>

        <div class="w-full slide-wrapper">
          <vueper-slides
            class="no-shadow"
            :bullets="false"
            :autoplay="false"
            :arrowsOutside="true"
            :gap="1"
            :touchable="false"
            fixed-height="305px"
            :visible-slides="this.windowWidth < 640 ? 1 : 3"
            :slide-ratio="1/3"
            @slide="onSlide"
          >
            <vueper-slide
              v-for="(i, index) in portfolioImages"
              :key="index"
              :image="i"
              @click.native="openViewer"
            >
            </vueper-slide>
          </vueper-slides>
        </div>
      </div>

      <div class="detail">
        <h1>상세설명</h1>
        <p class="explain">
          {{ data.explanationDetail }}
        </p>
      </div>
    </div>

    <div class="hidden">
      <ImageViewer :images="portfolioImages" />
    </div>
  </InteriorLayout>
</template>

<script>
import ImageViewer from "@/components/ImageViewer.vue";
import InteriorLayout from "@/components/InteriorLayout";
import services from "@/services";
import utils from "@/utils";
import dayjs from "dayjs";
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";
import _ from "lodash";
import { mapState } from "vuex";

export default {
  name: "InteriorOfficeDetail",
  components: {
    InteriorLayout,
    VueperSlides,
    VueperSlide,
    ImageViewer,
  },
  data() {
    return {
      data: {
        title: "",
        explanation: "",
        explanationDetail: "",
        isConfirm: false,
        addr: "",
      },
      currentIndex: 0,
      isFavorite: false,
      windowWidth: window.innerWidth,
    };
  },
  mounted() {
    this.getData();
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },
  watch: {
    windowWidth(newWidth) {
      this.windowWidth = newWidth;
    },
  },
  beforeDestroy() { 
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    async getData() {
      const { id } = this.$route.params;
      const data = await services.interiorOfficeDetail(id);
      this.data = data;
      this.isFavorite = !!this.subscribe;
    },
    onFavorite() {
      if (!this.isLoggedIn) {
        return;
      }

      if (this.isFavorite) {
        this.removeFavorite();
      } else {
        this.addFavorite();
      }
    },
    async addFavorite() {
      const success = await services.interiorAddSubscribe(
        this.userId,
        this.data.id
      );
      if (success) {
        this.isFavorite = true;
        this.$toast.open("찜이 되었습니다");
      } else {
        this.$toast.open({ message: "문제가 발생했습니다", type: "error" });
      }
    },
    async removeFavorite() {
      const success = await services.interiorRemoveSubscribe(this.data.id);
      if (success) {
        this.isFavorite = false;
        this.$toast.open("찜해제 되었습니다");
      } else {
        this.$toast.open({ message: "문제가 발생했습니다", type: "error" });
      }
    },
    onSlide({ currentSlide }) {
      const { index } = currentSlide;
      this.currentIndex = index;
    },
    openViewer() {
      this.$viewerApi({
        images: this.portfolioImages,
      });
    },
    goToOffer() {
      this.$router.push({ name: "InteriorOfferCreate" });
    },
  },
  computed: {
    address() {
      const { addr } = this.data;
      const splited = addr.split(" ");
      if (splited.length > 1) {
        return `${splited[0]} ${splited[1]} 위치`;
      } else if (splited.length === 1) {
        return `${splited[0]} 위치`;
      }
      return "";
    },
    subscribe() {
      const { hasUserSubscribeInteriorOffices } = this.data;
      if (hasUserSubscribeInteriorOffices) {
        return hasUserSubscribeInteriorOffices.find(
          (s) => s.userId === this.userId
        );
      }
      return null;
    },
    image() {
      const { hasInteriorOfficeFiles, hasThumbnail } = this.data;
      if (hasInteriorOfficeFiles && hasInteriorOfficeFiles.length > 0) {
        return utils.getImageUrl(hasInteriorOfficeFiles[0]);
      } else if (hasThumbnail) {
        return utils.getImageUrl(hasThumbnail);
      }
      return "";
    },
    date() {
      if (!this.data.createdAt) {
        return "";
      }
      return dayjs(this.data.createdAt).format("YYYY.MM.DD");
    },
    portfolioImages() {
      const { hasPortfolios, hasInteriorOfficeFiles } = this.data;
      const results = [];

      if (hasPortfolios) {
        const portfolios = hasPortfolios.map((p) => {
          const np = [];
          const { hasPortfolioInnerFiles, hasPortfolioOtherFiles } = p;

          if (hasPortfolioInnerFiles) {
            for (let i = 0; i < hasPortfolioInnerFiles.length; i++) {
              np.push(utils.getImageUrl(hasPortfolioInnerFiles[i]));
            }
          }

          if (hasPortfolioOtherFiles) {
            for (let i = 0; i < hasPortfolioOtherFiles.length; i++) {
              np.push(utils.getImageUrl(hasPortfolioOtherFiles[i]));
            }
          }

          return np;
        });
        _.flatten(portfolios).forEach((p) => {
          results.push(p);
        });
      }

      if (hasInteriorOfficeFiles) {
        hasInteriorOfficeFiles.forEach((f) => {
          results.push(utils.getImageUrl(f));
        });
      }

      return results;
    },
    ...mapState("user", ["isLoggedIn"]),
  },
};
</script>

<style scoped lang="scss">
.interior-office-wrapper {
  margin-bottom: 190px;

  .thumbnail-img-wrap {
    width: 100%;
    // padding-bottom: 96%; // w: 500px, h: 480px 기준
    height: 305px;
    position: relative;
    margin-bottom: 20px;
    .thumbnail-img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #ddd;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
    i {
      top: 10px;
      right: 10px;
      cursor: pointer;
    }
  }

  .place {
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.64px;
    text-align: left;
    color: #7ca3aa;
  }

  h1 {
    font-size: 30.5px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -1.22px;
    text-align: left;
    color: #222;
    margin-top: 15px;
    margin-bottom: 25px;
  }

  .explain {
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #7ca3aa;
  }

  .date {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.32px;
    text-align: left;
    color: #717171;
    margin-top: 20px;
  }

  .hint {
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.28px;
    text-align: left;
    color: #b0b0b0;
    margin-top: 40px;
  }

  .detail {
    margin-top: 70px;
  }

  .button-group {
    margin-top: 30px;

    button {
      width: 145px;
      height: 50px;
      border-radius: 10px;
      background-color: #293bf0;

      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.38;
      letter-spacing: -0.64px;
      text-align: left;
      color: #fff;

      &:first-child {
        margin-right: 15px;
      }

      i {
        margin-right: 5px;
      }
    }
  }

  .portfolio {
    margin-top: 70px;
  }

  .slide-wrapper {
    height: 305px;
  }
}

@media (max-width: 639px) {
  .interior-office-wrapper {
    .thumbnail-img-wrap {
      height: 200px;
    }
    .place {
      font-size: 12px;
    }
    h1 {
      font-size: 20px;
      margin-top: 10px;
      margin-bottom: 15px;
    }
    .explain {
      font-size: 12px;
    }
    .date {
      font-size: 12px;
      margin-top: 10px;
    }
    .hint {
      font-size: 11px;
      margin-top: 30px;
    }
    .button-group {
      button {
        width: 100px;
        height: 40px;
        font-size: 12px;
      }
    }
    .slide-wrapper {
      width: calc(100% - 60px);
      margin: 0 auto;
      height: 200px;
      .vueperslides {
        height: 100% !important;
      }
    }
  }
}
</style>
